import { ConnectWallet, useAddress, useContract, useContractRead, Web3Button } from "@thirdweb-dev/react";
import { useState } from "react";
import "./styles/Home.css";
import { utils } from "ethers";

export default function Home() {

//const contractAdr='0xe4f78966414ec2f9F658B2A3A7817fACf5FaefB7';//test
const contractAdr='0x217DdDd9F9A140AA401f9F4c5c09C47D08955432';//test
const address = useAddress();
const [quantity , setQuantity ] = useState(1);
const {contract}= useContract(contractAdr);
const [mintLoading, setMintLoading] = useState(false);
const isConnected = !!address;
const getOpenSeaURL = (tokenId: string | number) =>  `https://testnets.opensea.io/assets/mumbai/${contractAdr}/${tokenId}`;
const [mintedTokenId, setMintedTokenId] = useState<number>();
const [mintError, setMintError] = useState("");
const [mintSuccess, setMintSuccess] = useState(false);
const [registerSuccess, setRegisterSuccess] = useState(false);
const [registerLoading, setRegisterLoading] = useState(false);

const mintingActive=true;
const registerActive = false;

const { data: price, isLoading: loading1 } = useContractRead(contract, "priceForAddress", address, quantity)
const { data: tokens, isLoading: loading2 } = useContractRead(contract, "getFreeAllowanceMax", address)
const { data: claimed, isLoading: loading3 } = useContractRead(contract, "getClaimedForFree", address)


  return (
    <div className="container">
      <main className="main">
      {mintingActive ? (
        <h1 className="title">
          Hunter Boys Minting!
        </h1>
      ):""}
      {registerActive ? (
        <h1 className="title">
          Hunter Boys Free Mint Registration!
        </h1>
      ):""}

        <center><img src="./preview_mixed_1.gif" alt="" width="200"></img>
        <img src="./preview_mixed_2.gif" alt="" width="200"></img>
        <img src="./preview_mixed_3.gif" alt="" width="200"></img>
        </center>  

        <p className="description">
          
          Starting Price: 3 MATIC/HUNTER 
          <br></br>
          Mint price will increase in every cycle we announce from Discord & Twitter.
          
        </p>


        <div className="connect">
          <ConnectWallet />
        </div>
        

      
        <div style={{ marginTop : "20px"}}></div>
    <center><p>{claimed? 'You have '+tokens+' free NFTs to claim!' : ""}
    <br></br>{claimed && claimed[2] && claimed[2]!=="" ? 'Your registered free NFTs ?: '+(claimed[2]+'').replaceAll('0','').replaceAll(',','')+' ' : ""}
    <br></br>{claimed && claimed[3] && claimed[3]!=="" ? 'Your claimed(minted) free NFTs ?: '+(claimed[3]+'').replaceAll('0','').replaceAll(',','')+' ' : ""}
    </p></center>
    
    {( registerActive && (
    <Web3Button
         isDisabled={!isConnected || !registerActive || registerLoading || registerSuccess || (price? utils.formatEther(price)==="0.0" : false)} 
      contractAddress={contractAdr}
      action={async (contract) => {
        try {
          if (tokens<=0) {
            setMintError("Sorry. You have no GHOST NFTs for free minting!");
          }
          else {
            setRegisterLoading(true);
            setMintError("");
            setRegisterSuccess(false);
            /*const tx = await mint({
              args: [address, { value: utils.parseEther('0.001') }],
            });*/
            const tx = await contract.call("setAllowedForFree", address, tokens);
            console.log('TX', tx);

            /*const receipt = await tx.getTransactionReceipt();
            console.log('TX receipt', receipt);
            // @ts-ignore
            const mintedTokenId = await receipt.events[0].args[2].toString();
            setMintedTokenId(mintedTokenId);*/
            setRegisterSuccess(true);
          }
          
        } catch (error) {
          console.error(error);
          //setMintError(error+"");
          setMintError("Please check your wallet balance for gas fees and try again!")
        } finally {
          setRegisterLoading(false);
        }
      }}
    >
     🎉 Register
      
      
    </Web3Button>    ))}

    <center> {registerLoading && <label style={{ marginTop : "20px"}}>Registering... please wait</label>}</center>

{((registerSuccess || (price? utils.formatEther(price)==="0.0" : false)) && registerActive) && (
  <center><p style={{ marginTop : "20px"}}>
    🥳 Registration successful! 
    </p><p style={{ marginTop : "20px"}}>
    We will inform you about minting date and time. 
    </p></center>
)}


<div style={{ marginTop : "40px"}}></div>
        <div>
          <label>
            Quantity:
            <input disabled={!isConnected || mintLoading || !mintingActive} min="1" max="15" type="number" style={{ fontSize: "1.5rem", width:"50px"}}  value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))}></input>
          </label>
        </div>
        <div style={{ marginTop : "20px"}}>
        <Web3Button
         isDisabled={!isConnected || mintLoading || !mintingActive} 
        
      contractAddress={contractAdr}
      action={async (contract) => {
        try {
          if (quantity<=0) {
            setMintError("Please select quantity ( > 0 )");
          }
          else {
            setMintLoading(true);
            setMintError("");
            setMintSuccess(false);
            /*const tx = await mint({
              args: [address, { value: utils.parseEther('0.001') }],
            });*/
            const tx = await contract.call("claim", address, quantity, {value :price});
            console.log('TX', tx);

            /*const receipt = await tx.getTransactionReceipt();
            console.log('TX receipt', receipt);
            // @ts-ignore
            const mintedTokenId = await receipt.events[0].args[2].toString();
            setMintedTokenId(mintedTokenId);*/
            setMintSuccess(true);
          }
          
        } catch (error) {
          console.error(error);
          //setMintError(error+"");
          setMintError("Please check your wallet balance for price + gas fees and try again!")
        } finally {
          setMintLoading(false);
        }
      }}
    >
     🎉 Mint ({price? utils.formatEther(price) : ""} MATIC)
      
      
    </Web3Button>     
    
     {!mintingActive && (
        <label style={{ marginTop : "20px"}}>⛔️ Minting is not active yet. TBD.</label>
      )}
    

    {mintError && (
        <label style={{ marginTop : "20px"}}>⛔️ Mint error:</label>
      )}

      {mintError && (
        <label style={{ marginTop: '8px', color: 'red' }}>
          <code>{JSON.stringify(mintError, null, ' ')}</code>
        </label>
      )}
    

    <center> {mintLoading && <label style={{ marginTop : "20px"}}>Minting... please wait</label>}</center>

      {mintSuccess && (
        <label style={{ marginTop : "20px"}}>
          🥳 Mint successful! You can view your NFTs{' '}
          <a
            target="_blank"
            href="https://opensea.io/account"
            color='blue'
            rel="noreferrer"
          >
            here!
          </a>
        </label>
      )}

    </div>

    <p style={{ marginTop : "100px"}}>
    Follow us on twitter and discord  to get more information. {' '}
    </p>
    <p style={{ marginTop : "20px"}}>
    <a
      target="_blank"
      href="https://twitter.com/ghoverse"
      color='blue'
      rel="noreferrer"
    >
      Twitter
    </a>  |  <a
      target="_blank"
      href="https://discord.gg/ukgzhjRpng"
      color='blue'
      rel="noreferrer"
    >
      Discord
    </a> 
  </p>
      </main>
    </div>
  );
}
